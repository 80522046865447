.rating-list-item {
  padding: 24px 0;
  
  &:not(:last-child) {
    border-bottom: 1px solid #EEEEEE;
  }
  
  .text-red {
    color: red;
  }

  .text-green {
    color: #16B55E;
  }

  .p-0 {
    padding: 0;
  }

  .author-container {
    margin-bottom: 1rem;

    .author-profile-pic {
      width: 3.5rem;
      height: 3.5rem;
      object-fit: cover;
      border-radius: 50px;
      margin-right: 1rem;
    }

    .author-name {
      font-weight: 600;
      font-size: medium;
    }

    .comment-date {
      font-size: small;
      color: #A4A4A4;
    }
  }

  .rating-hire-again {
    margin-bottom: 0.5rem;
  }

  .rating-star-item {
    justify-content: space-between;
    align-items: baseline;

    .item-label {
      font-weight: 600;
    }

    .rating-stars {
      color: #574DD3;
    }
  }

  .btn-view-comment {
    display: flex;
    align-items: center;
    color: #574DD3;
    font-weight: 600;
    padding: 0;
  
    span {
      margin-left: 0.5rem;
    }
  }
}