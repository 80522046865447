.page-hirer-roles {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .project {

    .title-container {
      .button-container {
        align-items: center;
        justify-content: flex-end;
        margin: auto 0;

        .more-action-btn .component-button {
          border: 0;
          font-size: x-large;
        }
      }

      h4.ant-typography {
        flex-grow: 1;
      }
    }

    .description {
      max-width: calc(100vw - 32px);
      overflow-wrap: break-word;
    }

    .details {
      .attribute {
        align-items: center;
        padding: 4px 0;

        .label {
          flex: 0 1 136px;
          text-align: right;
          padding-left: 16px;
          padding-right: 8px;
          font-size: 0.75rem;
          line-height: 0.75rem;

          &.date {
            align-self: flex-start;
            margin-top: 10px;
          }
        }

        .value {
          flex: 1 1 auto;
          text-align: left;
          padding-right: 16px;
          padding-left: 8px;
          font-size: 1.2rem;

          small {
            display: inline-block;
            font-size: 0.75rem;
            vertical-align: middle;
            width: 48px;
            margin-right: 8px;
            text-align: right;
          }
        }
      }
    }
  }

  .role-list {
    padding: 16px 0;
  }

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
}
