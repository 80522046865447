.page-hirer-applicants {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .role {
    .title-container {
      .button-container {
        align-items: center;
        justify-content: flex-end;
        margin: auto 0;

        .more-action-btn .component-button {
          border: 0;
          font-size: x-large;
        }
      }

      h4.ant-typography {
        flex-grow: 1;

        small {
          text-transform: uppercase;
          margin-left: 8px;
          font-size: 0.75rem;
        }
      }
    }

    .role-details {  
      .attribute {
        align-items: center;
        padding: 4px 0;
  
        .label {
          flex: 0 1 136px;
          text-align: right;
          padding-right: 8px;
          font-size: 0.75rem;
          line-height: 0.75rem;

          &.date {
            align-self: flex-start;
            margin-top: 10px;
          }
        }
  
        .value {
          flex: 1 1 auto;
          text-align: left;
          padding-left: 8px;
          font-size: 1.2rem;
  
          small {
            font-size: 0.75rem;
            vertical-align: middle;
          }

          &.capitalize {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .select-filter {
    margin-top: 32px;

    .selector {
      display: block;
    }
  }

  .applicant-list {
    padding: 16px 0;
  }

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
  }
}
