.project-card {
  box-shadow: 0 2px 6px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .ant-typography {
    display: block;
  }



  .header {
    padding: 16px;
    align-items: center;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);;

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      color: #fff;
      text-transform: capitalize;
    }

    .btn-edit {
      color: white;
      font-size: 1.5rem;
      border: none;
      background-color: transparent;
    }
  }

  .summary {
    border-top: 1px solid rgba(0,0,0,0.1);

    .attribute {
      text-align: center;
      padding: 4px;

      .label {
        font-size: 0.75rem;
      }

      .value {
        font-size: 1.3rem;
      }
    }
  }

  .description {
    padding: 16px;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    text-align: center;
    max-width: calc(100vw - 64px);
    overflow-wrap: break-word;
  }

  .details {
    border-bottom: 1px solid rgba(0,0,0,0.1);

    .attribute {
      align-items: center;
      padding: 4px 0;

      .label {
        flex: 0 1 136px;
        text-align: right;
        padding-left: 16px;
        padding-right: 8px;
        font-size: 0.75rem;
        line-height: 0.75rem;

        &.date {
          align-self: flex-start;
          margin-top: 10px;
        }
      }

      .value {
        flex: 1 1 auto;
        text-align: left;
        padding-right: 16px;
        padding-left: 8px;
        font-size: 1.2rem;

        small {
          display: inline-block;
          font-size: 0.75rem;
          vertical-align: middle;
          width: 48px;
          margin-right: 8px;
          text-align: right;
        }
      }
    }
  }

  .action {

    .action-button,
    .unpaid-action-button .component-button {
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      background-color: #fff;
      color: #574dd3;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }

      .ant-typography {
        color: #574dd3;
        font-weight: 600;
        font-size: 1.05rem;
      }
    }

    .unpaid-action-button {
      display: flex;
      justify-content: center;
      align-items: center;

      .component-button:disabled {
        cursor: not-allowed;

        .ant-typography {
          color: gray;
        }

        svg path {
          fill: gray;
        }
      }

      .action-button-icon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 16px;
        color: #574dd3;

        svg path {
          fill: #574dd3
        }
      }
    }
  }
}