.prompt-component-footer {
  background-color: #000000;
  color: #fff;
  padding-bottom: 24px;

  .step-guide-box {
    padding: 20px 50px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .step-guide {
    margin-bottom: 3rem;
  }

  .footer-btn {
    margin: 16px;
  }
}
