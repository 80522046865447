.page-profile-section {
  width: 100%;
  padding: 0;

  .section-list {
    display: flex;
    flex-direction: column;

    .section-list-item {
      padding: 1rem 1.5rem;
      background-color: transparent;
      border: none;
      text-align: left;
      font-weight: 600;
      cursor: pointer;
      display: block;
      color: #000;
      background-color: #fff;

      &:hover {
        color: #000;
        background-color: #e5e5e5;
        transition: background-color 300ms linear;
      }

      &:focus {
        outline: 2px solid;
      }
    }
  }
}
