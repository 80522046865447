$project-title: 1.063rem; //17px
$role-title: 0.875rem; // 14px
$card-section-divider: 1px solid rgba(0, 0, 0, 0.1);
$border-radius: 4px;

.confirmation-card {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.23);

  .project-title {
    font-size: $project-title;
    display: block;
    padding: 1rem;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .role-title {
    background: #d9d9d9;
    font-size: $role-title;
    justify-content: space-between;

    span.ant-typography {
      display: flex;
      align-items: center;
    }
  }

  .role-details {
    border-bottom: $card-section-divider;


    .capitalize {
      text-transform: capitalize;
    }

    .bold {
      font-weight: 600;
    }


    .icon {
      margin-right: 0.5rem;
    }

  }

  .role-action {

    a,
    button {
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }


      &:first-child {
        border-bottom-left-radius: $border-radius;
        background-color: #fff;

        .ant-typography {
          color: #574dd3;
          font-weight: 600;
          font-size: 1.05rem;
        }

        svg {
          color: #574dd3;
        }
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
        background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);

        .ant-typography {
          color: white;
          font-weight: 600;
          font-size: 1.05rem;
        }

        svg {
          color: white;
        }
      }
    }
  }
}