.page-about-us {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .statement {
    padding: 80px 48px 40px 48px;
  }

  .footer {
    margin-top: auto;
  }

  @media screen and (max-width: 600px) {
    .statement {
      padding: 80px 16px 40px 16px;
    }
  }
}
