.bottom-nav {
  position: fixed;
  max-width: inherit;
  width: 100%;
  bottom: 0;
  background-color: white;
  box-shadow: 0 -2px 36px -20px black;
  z-index: 200;

  .bottom-nav-item {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    padding: 5px;
    color: black;
    width: 85px;
    align-items: center;

    .ant-badge {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      color: inherit;
      font-weight: inherit;
    }

    svg {
      font-size: 22px;
      margin: 4px 0;
    }

    &:hover {
      background-color: #d7dbdd;
      transition: background-color 150ms linear;
    }

    &.--active {
      color: #574dd3;
      font-weight: 600;
    }
  }
}
