.page-hirer-projects {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .title-container {
    justify-content: space-between;
    align-items: center;
    
    h4.ant-typography {
      flex: 1 1 0;
    }

    .button-container {
      justify-content: end;
    }
  }

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #574dd3;
    font-weight: 600;
  }

  .ant-tabs-ink-bar {
    background-color: #574dd3;
  }
  
  a.ant-typography, .ant-typography a {
    color: #574dd3;
    font-weight: 600;

    &:visited {
      color: #574dd3;
    }
  }
}

.tab-hirer-projects {
  min-height: 80vh;
}
