.component-banner {
    z-index: 10;
    padding: 1rem;
    max-width: inherit;
    width: 100vw !important;
    align-items: center;
    background-color: black;
    transition: all 0.5s ;
    transition-timing-function: ease-in-out;
    opacity: 1;
    margin-top: -20px;

    .component-button {
      background-color: #574DD3;
      color: white;
    }

    .logo {
      width: 45px;
    }

    .name {
        font-size: 12px;
        font-weight: 400;
        font-family: Nunito;
        line-height: 16.37px;
        color: white;
      }

      &.--hide {
        transform: translateY(-75px);
        opacity: 0;
      }
}

@keyframes banner-slide {
  from {top: -103px;}
  to {top: -25px;}
}
  