$border-radius: 4px;
$card-section-divider: 1px solid rgba(0, 0, 0, 0.1);

.role-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: $border-radius;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .ant-typography {
    display: block;
  }

  .role-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 16px;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline;
    }

    .subtitle {
      display: inline;
      margin-left: 16px;
      font-size: 0.75rem;
      color: #fff;
      text-transform: uppercase;
    }

    .btn-edit {
      color: white;
      font-size: 1.5rem;
      border: none;
      background-color: transparent;
    }
  }

  .role-summary {
    border-top: $card-section-divider;
    border-bottom: $card-section-divider;

    .attribute {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 4px;

      .label {
        font-size: 0.75rem;
      }

      .value {
        font-size: 1.3rem;
      }
    }

    .description {
      flex: 1 1 0;
      padding: 16px;
      margin: 0;
      text-align: center;
      // border-left: $card-section-divider;
    }
  }

  .role-details {
    border-bottom: $card-section-divider;

    .attribute {
      align-items: center;
      padding: 4px 0;

      .label {
        flex: 0 1 144px;
        text-align: right;
        padding-right: 8px;
        font-size: 0.75rem;
        line-height: 0.75rem;

        &.--align-top {
          align-self: flex-start;
          margin-top: 10px;
        }
      }

      .value {
        flex: 1 1 auto;
        text-align: left;
        padding-left: 8px;
        font-size: 1.2rem;

        small {
          font-size: 0.75rem;
          vertical-align: middle;
        }

        &.capitalize {
          text-transform: capitalize;
        }
      }
    }
  }

  .role-action {
    a,
    button {
      flex: 1 1 0;
      padding: 16px 0;
      text-align: center;
      border: none;
      background-color: #fff;
      color: #574dd3;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        font-size: 1.5rem;
        margin-right: 16px;
      }

      .ant-typography {
        color: #574dd3;
        font-weight: 600;
        font-size: 1.05rem;
      }

      &:first-child {
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius;
      }

      svg {
        color: #574dd3;
      }
    }
  }
}
