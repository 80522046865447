.component-talent-invitation {
  padding: 0 0.75rem;

  .capitalize {
    text-transform: capitalize;
  }

  .bold {
    font-weight: 600;
  }

  .italic {
    font-style: italic;
  }

  .radio-group-timeslot {
    display: flex;
    flex-wrap: wrap;

    .radio-timeslot {
      margin: 0.5rem;
    }
  }

  .casting-location-address {
    padding: 0;
    display: flex;
    height: auto;
    align-items: baseline;

    span {
      word-break: normal;
      white-space: initial;
      text-align: left;
      line-height: normal;
    }
  }
}
