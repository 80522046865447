.page-hirer-form {
  margin-top: 56px;
  padding: 16px;
  min-height: 80vh;

  .add-calendar {
    margin-left: 0.85rem;
    color: black;
    border: none;
  }

  .btn-delete {
    margin-top: 16px;
  }

  .date-selection-switch.ant-switch {
    background-color: #1890ff;
  }

  .tentative-tag {
    font-weight: 600;
    margin-left: 8px;
  }

}

.project-form-shoot-date {
  @media (max-width: 480px) {
    .ant-picker-panels {
      width: min-content;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}
